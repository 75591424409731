import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SharedService { 
    
  constructor(private http: HttpClient) { }

  checkValidityNumber = (control: AbstractControl) =>  control.value.toString()[0] == '1' || control.value.toString()[0] == '2'  ?  null : { invalidNumber: true };

  GetCaptcha = () => this.http.get<any>(environment.baseUrl + "Captcha/Get")
  
}
